<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>Bienvenue {{$session.get('person').firstname}} ! 🎉</h1>
        <!--p>Nouvelle version de Fairly Made API (#Migration SQL). Des bugs peuvent survenir durant les premiers jours. </p-->
      </v-col>

      <!-- Total clients --
      <DashboardSmallCard
        :card-title="$t('dashboard.cards.clients.total.title')"
        :card-text="this.cards.clients.total"
      /-->

      <!-- Total coachs --
      <DashboardSmallCard
        :card-title="$t('dashboard.cards.coachs.total.title')"
        :card-text="this.cards.coachs.total"
      /-->

      <!-- Total members --
      <DashboardSmallCard
        :card-title="$t('dashboard.cards.fm-accounts.total.title')"
        :card-text="this.cards.fm-accounts.total"
      /-->

      <!-- Total active members --
      <DashboardSmallCard
        :card-title="$t('dashboard.cards.fm-accounts.active.title')"
        :card-text="this.cards.fm-accounts.totalActive"
      /-->

    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Dashboard",

  components: {
    //DashboardSmallCard: () => import("@/components/Dashboard/SmallCard")
  },

  data() {
    return {
      cards: {
        clients: {
          total: null
        }
      }
    }
  },

  created() {
    //this.getCards();
    this.$store.commit("pageHead/setTitle", this.$t('dashboard.pageTitle'));
  },

  methods: {
    getCards() {

      this.$http.get(`/dashboard/cards`, {
        headers: {Authorization: "Bearer " + this.$session.get('jwt')}
      })
        .then(res => {
          this.cards = res.data;
        })
        .catch(err => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          //this.isLoaded = true;
        })
    }
  }
}
</script>

<style scoped>

</style>
